import React from "react"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const LoginSignupPage = () => (
  <Layout>
    <SEO title="Quiz" />
    <main>
        <div className="standard-container">
            <h2 className="relative uk-text-center mt6 mb5">
                Login/Signup
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
            </h2>
            <div className="uk-margin">
              <div class="toggle-animation-queued uk-margin-small">
                <form className="uk-form-horizontal dark-form">
                    <div className="uk-margin">
                        <label className="uk-form-label" for="name">NAME</label>

                        <div className="uk-form-controls">
                            <input className="uk-input" id="name" type="text" value="Your Name"  />
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" for="email">EMAIL</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="form-horizontal-text" type="email" value="yourname@example.com" />               
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" for="email">PASSWORD</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="form-horizontal-text" type="password" value="something" />    
                        </div>
                    </div>
                    <div className="uk-margin uk-text-right">
                        <button className="uk-button uk-button-default" type="submit">Submit</button>
                    </div>
                </form>
              </div>
              <div class="toggle-animation-queued" hidden>
                <form className="uk-form-horizontal uk-margin-large dark-form">
                    <div className="uk-margin">
                        <label className="uk-form-label" for="email">EMAIL</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="form-horizontal-text" type="email" value="email" />               
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" for="email">PASSWORD</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="form-horizontal-text" type="password" value="something" />    
                        </div>
                    </div>
                    <div className="uk-margin uk-text-right">
                        <button className="uk-button uk-button-default" type="submit">Submit</button>
                    </div>
                </form>
              </div>
              <div className="flex flex-column flex-row-ns justify-center items-center">
                <button class="uk-button uk-button-secondary uk-border-pill" type="button" data-uk-toggle="target: .toggle-animation-queued; animation: uk-animation-fade; queued: true; duration: 300">
                    <span className="toggle-animation-queued">Login</span>
                    <span className="toggle-animation-queued" hidden>Signup</span>
                </button>
                <Link className="uk-link ma2 uk-text-muted" to="/quiz/forgot-password">
                    Forgot Password
                </Link>
              </div>
            </div>
        </div>
    </main>
  </Layout>
)

export default LoginSignupPage
